

















import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OleMissL5PostLabPart4',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part5_4: null,
      },
      questions: [
        {
          text: 'The news article states that an anonymous Space-M employee gave a list of materials used to build the rocket. He stated that the list was not exhaustive. Do you trust what the employee has to say about the materials? Why or why not?',
          inputName: 'part5_4',
        },
      ],
    };
  },
};
